import React, { PropTypes } from 'react';
import classNames from 'bbc-morph-classnames';

const placeholderSrc =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

function getInterpolatedSrc(url, width) {
  return url.replace(/{width}/g, width);
}

function getSrcSet(src, chefSizes, srcsetMode) {
  const srcSet = chefSizes.map((size, index) => {
    const imageData = (srcsetMode === 'x' ? index + 1 : size) + srcsetMode;
    const interpolatedSrc = getInterpolatedSrc(src, size);
    return `${interpolatedSrc} ${imageData}`;
  });
  return srcSet.join(', ');
}

const SrcsetImage = (className, srcset, alt, sizes, src) => {
  const defaultClassNames = ['qa-srcset-image'];

  return (
    <img
      src={src}
      sizes={sizes}
      srcSet={srcset}
      alt={alt}
      className={classNames(defaultClassNames, className)}
    />
  );
};

const LazyLoadImage = (className, src, alt, chefSizes) => {
  const widths = JSON.stringify(chefSizes);
  const defaultClassNames = ['qa-lazyload-image', 'lazyload', 'lazyautosizes'];

  return (
    <img
      src={placeholderSrc}
      data-src={src}
      data-widths={widths}
      data-sizes="auto"
      alt={alt}
      className={classNames(defaultClassNames, className)}
    />
  );
};

const PlaceholderImage = () => {
  return <img alt="" src={placeholderSrc} />;
};

const ResponsiveImage = ({ src, width, className, alt, chefSizes, sizes, srcsetMode, delayed }) => {
  if (delayed) {
    return LazyLoadImage(className, src, alt, chefSizes);
  }

  if (src) {
    const srcset = getSrcSet(src, chefSizes, srcsetMode);
    const interpolatedSrc = getInterpolatedSrc(src, width);
    return SrcsetImage(className, srcset, alt, sizes, interpolatedSrc);
  }

  return PlaceholderImage();
};

ResponsiveImage.displayName = 'ResponsiveImage';

ResponsiveImage.propTypes = {
  className: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  src: PropTypes.string,
  alt: PropTypes.string,
  chefSizes: PropTypes.arrayOf(PropTypes.number),
  delayed: PropTypes.bool,
  sizes: PropTypes.string,
  width: PropTypes.number,
  isPlaceholder: PropTypes.bool,
  srcsetMode: PropTypes.string,
};

ResponsiveImage.defaultProps = {
  alt: '',
  chefSizes: [240, 320, 480, 624, 800],
  sizes: '(min-width: 900px) 50vw, (min-width: 600px) 70vw, 100vw',
  srcsetMode: 'w',
  width: 320,
};

export default ResponsiveImage;
